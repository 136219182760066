

import 'mapbox-gl/dist/mapbox-gl.css';

import React, { useState, useEffect, useRef } from 'react';
import { layerSpecFromID } from './layers';

import { csvParse } from "d3-dsv";
import { scaleSequential } from 'd3-scale';
import { interpolateSpectral } from 'd3-scale-chromatic';
import * as turf from '@turf/turf';


import mapboxgl from '!mapbox-gl'; // eslint-disable-line

mapboxgl.accessToken = 'pk.eyJ1Ijoic2ZhcmxleTIiLCJhIjoiY2lmeWVydWtkNTJpb3RmbTFkdjQ4anhrMSJ9.jRJCOGU1AOHfNXHH7cwU7Q';


function colorMap(spec) {
    if (spec.breaks.length > 2) {
        let map;
        if (!spec.normalize_and_max) {
            map = [
                'interpolate',
                ['linear'],
                ['to-number', ['get', spec['layer']]]
            ]
        } else {
            map = [
                'interpolate',
                ['linear'],
                ['min', ['/', ['to-number', ['get', spec['layer']]], spec.normalize_and_max], 100]
            ]
        }
        for (let i = 0; i < spec.colors.length; i++) {
            const color = spec.colors[i];
            const breakpoint = spec.breaks[i];
            map.push(breakpoint);
            map.push(color);
        }
        return map;
    } else {
        let map = [
            'match',
            ['to-string', ['get', spec['layer']]]
        ]
        for (let i = 0; i < spec.colors.length; i++) {
            const color = spec.colors[i];
            const breakpoint = spec.breaks[i];
            map.push(breakpoint);
            map.push(color);
        }
        map.push('black');
        return map;
    }
}

function demographicOverlayStyle(source, table, denominator) {
    let dataval = (denominator === 'None') ? ['get', table] : ['/', ['get', table], ['get', denominator]];

    let maxval = 1;
    if (['B19013001'].includes(table)) {
        maxval = 250000
    } else if (['B25077001'].includes(table)) {
        maxval = 2500000
    } else if (['B02001003', 'B25077001', 'B02001004',
        'B02001005', 'B02001002', 'B28010007'].includes(table)) {
        maxval = 0.05
    }
    else if (['B03003003'].includes(table)) {
        maxval = 0.25
    }
    else if (['C16001005', 'C16001020', 'C16001023',
        'C16001028', 'C16001035', 'C16001011',
        'C16001014', 'C16001017'
    ].includes(table)) {
        maxval = 0.01
    } else if (['B01002001'].includes(table)) {
        maxval = 100
    } else if (['B25003003'].includes(table)) {
        maxval = 0.5
    }

    return [
        'interpolate',
        ['linear'],
        dataval,
        0,
        '#edf8e9',
        maxval / 2,
        '#74c476',
        maxval,
        '#006d2c'
    ]
}

function Map(props) {
    const [mapStyle, setMapStyle] = useState('mapbox://styles/sfarley2/cle4xqcs9000001mgvmrbyroj?fresh=true');
    let centroids;
    let parcelData;
    const mapContainer = useRef(null);
    const map = useRef(null);

    useEffect(() => {
        async function loadParcelData() {
            const r1 = await fetch("./parcel_centroids.geojson");
            const parcelCentroids = await r1.json();
            centroids = parcelCentroids;
            const resp = await fetch("./scores.csv");
            const text = await resp.text();
            const data = csvParse(text);
            const parcelMapped = {}
            data.forEach((d) => {

                const parcelID = d['parcel_id'];

                if (!parcelMapped[parcelID]) {
                    parcelMapped[parcelID] = [];
                }
                parcelMapped[parcelID].push({ 'title': d.discovery_title, 'score': +d.total_risk });
            });
            parcelData = parcelMapped;
        }
        loadParcelData();
    }, []);

    useEffect(() => {
        const [source, table, denominator] = props.demographicLayer.split(".");

        let sourceUrl = `overlays/${source}.geojson`;
        if (source === 'None') {
            sourceUrl = { 'type': 'FeatureCollection', features: [] }
        }


        if (!map.current) {
            return;
        }
        if (map.current.isStyleLoaded()) {
            map.current.getSource('demographics').setData(sourceUrl);
            map.current.setPaintProperty('demographics', 'fill-color', demographicOverlayStyle(source, table, denominator));
            map.current.setFilter('demographics', ['!=', ['get', 'Name'], 'Marin County, CA']);
            map.current.setFilter('demographics', ['>', ['get', table], 0]);
        } else {
            map.current.once('load', () => {
                map.current.getSource('demographics').setData(sourceUrl);
                map.current.setPaintProperty('demographics', 'fill-color', demographicOverlayStyle(source, table, denominator));
                map.current.setFilter('demographics', ['!=', ['get', 'Name'], 'Marin County, CA']);
                map.current.setFilter('demographics', ['>', ['get', table], 0]);
            })
        }

    }, [props.demographicLayer]);

    useEffect(() => {
        if (!map.current) {
            return;
        }
        console.log(props.parcelFilter[0])
        if (map.current.isStyleLoaded()) {
            ['parcel-scoresgeojsonl', 'grid-statsgeojsonl'].forEach((f) => {
                map.current.setFilter(f, ['all', ['>=', ['get', 'parcel_normalized_sum_risk'], props.parcelFilter[0]], ['<=', ['get', 'parcel_normalized_sum_risk'], props.parcelFilter[1]]])
            })

        } else {
            map.current.once('load', () => {
                ['parcel-scoresgeojsonl', 'grid-statsgeojsonl'].forEach((f) => {
                    map.current.setFilter(f, ['all', ['>=', ['get', 'parcel_normalized_sum_risk'], props.parcelFilter[0]], ['<=', ['get', 'parcel_normalized_sum_risk'], props.parcelFilter[1]]])
                })
            })
        }
    }, [props.parcelFilter]);

    function initLayerFromSpec(spec) {
        const availableLayers = ['parcel-scoresgeojsonl', 'grid-statsgeojsonl']
        availableLayers.forEach((layer) => {
            map.current.setLayoutProperty(layer, 'visibility', 'none')
        })
        map.current.setLayoutProperty(spec.group, 'visibility', 'visible');
        const colormap = colorMap(spec);
        console.log(colormap)
        map.current.setPaintProperty(spec.group, 'fill-color', colormap);
        map.current.setPaintProperty(spec.group, 'fill-opacity', 0.7);
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle,
            center: [-122.57252, 38.03137],
            zoom: 9,
            hash: true
        });


        map.current.on('load', () => {

            map.current.addSource('demographics', { type: 'geojson', data: { type: 'FeatureCollection', features: [] } });
            map.current.addLayer({
                'source': 'demographics', 'id': 'demographics', type: 'fill', paint: {
                    'fill-color': 'red',
                    'fill-opacity': 0.5,
                    'fill-outline-color': 'white'
                }
            }, 'contour-label');

            map.current.on('click', 'demographics', (e) => {
                console.log(e.features[0])
            })

            map.current.on('mouseleave', 'parcel-scoresgeojsonl', (e) => {
                map.current.getCanvas().style.cursor = '';
            })
            map.current.on('mouseenter', 'parcel-scoresgeojsonl', (e) => {
                map.current.getCanvas().style.cursor = 'pointer';

            })
            map.current.on('click', 'parcel-scoresgeojsonl', (e) => {
                console.log(e.features[0].properties);
                const feature = e.features[0];
                const props = feature.properties;
                const coordinates = [e.lngLat.lng, e.lngLat.lat];
                const totalHazards = props.structure_normalized_total + props.vegetation_normalized_total;
                const percentStructure = (props.structure_normalized_total / totalHazards) * 100
                const percentVeg = (props.vegetation_normalized_total / totalHazards) * 100;

                const discoveries = parcelData[props.PropID].sort((a, b) => {
                    return b.score - a.score
                });
                const colorScale = interpolateSpectral;
                let text = `
                <h3>${props.PropID}</h3>
                    <p>${props.UseCdDesc}</p>
                    <hr/>
                    <p>Juristiction: ${props.Jurisdicti}</p>
                    <p>Fire Service: ${props.Label} ${(props.FireAuthor != props.Label) ? "(" + props.FireAuthor + ")" : ""}</p>
                    <p>Acres: ${props.Acres}</p>
                    <p>On Parcel Hazard: ${props.parcel_normalized_sum_risk.toFixed(1)}</p>
                    <p>On-Parcel Hazard Rank: ${(props.on_parcel_rank * 100).toFixed(1)} percentile </p>
                    <h5>Hazard Contributors</h5>
                    <p>Defensible Space:  ${percentVeg.toFixed(1)}% of Total</p>
                    <p> Home Hardening:  ${percentStructure.toFixed(1)}% of Total</p>

                    <h5>Hazard Components</h5>
                    `
                discoveries.forEach((d) => {
                    const color = colorScale(-0.01 * d.score + 0.5);
                    console.log(color)
                    text += `<p style='background-color: ${color}'>${d.title}: ${d.score.toFixed(1)}</p>`
                })
                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(text)
                    .addTo(map.current);
            })

            map.current.on('mouseleave', 'grid-statsgeojsonl', (e) => {
                map.current.getCanvas().style.cursor = '';
            })
            map.current.on('mouseenter', 'grid-statsgeojsonl', (e) => {
                map.current.getCanvas().style.cursor = 'pointer';

            });
            console.log(centroids)

            map.current.on('click', 'grid-statsgeojsonl', (e) => {
                const feature = e.features[0];
                const geometry = feature.geometry;

                const memberCentroids = turf.pointsWithinPolygon(centroids, geometry);


                const fprops = feature.properties;
                const coordinates = [e.lngLat.lng, e.lngLat.lat];
                const totalHazards = fprops.structure_normalized_total + fprops.vegetation_normalized_total;
                const percentStructure = (fprops.structure_normalized_total / totalHazards) * 100
                const percentVeg = (fprops.vegetation_normalized_total / totalHazards) * 100

                let text = `
                <h3>${fprops.discovery_count} discoveries (${memberCentroids.features.length} parcels)</h3>
                    <p>On Parcel Hazard: ${fprops.parcel_normalized_sum_risk.toFixed(1)}</p>
                    <p>Landscape Hazard: ${fprops.Total_Parc.toFixed(1)}</p>
                    <p>Composite Hazard: ${fprops.parcel_normalized_composite.toFixed(1)} </p>
                    <h5>Hazard Contributors</h5>
                    <p>Defensible Space:  ${percentVeg.toFixed(1)}% of Total</p>
                    <p> Home Hardening:  ${percentStructure.toFixed(1)}% of Total</p>
                    <h5>Hazard Factors</h5>
                    <p>Structure Separation Distance:  ${fprops.structure_sep_distance_mean.toFixed(1)}m </p>
                    <p>Ember Intersection Fraction:  ${(fprops.intersection_fraction * 100).toFixed(1)}% </p>
                    <p>Max Flight Distance:  ${(fprops.flight_distance).toFixed(1)}m </p>
                    <p>2017 Flame Length:  ${(fprops.flame_length_2017).toFixed(1)}ft </p>
                    <p>2020 Flame Length:  ${(fprops.flame_length_2020).toFixed(1)}ft </p>
                    
                    <h5 class='h5'>Member Parcels</h5>

                    `
                memberCentroids.features.forEach((f) => {
                    text += `<p>${f.properties.PropID} | ${f.properties.UseCdDesc}</p>`
                })


                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(text)
                    .addTo(map.current);
            })
        });
    }, [parcelData, centroids]);

    useEffect(() => {
        const layerSpec = layerSpecFromID(props.activeLayer)
        if (!map.current.isStyleLoaded()) {
            map.current.once('load', () => { initLayerFromSpec(layerSpec) })
        } else {
            initLayerFromSpec(layerSpec)
        }
    }, [props.activeLayer])

    function applyFilter(filterName) {
        if (filterName == 'all') {
            map.current.setFilter('parcel-scoresgeojsonl', null);
        } else {
            console.log(filterName)
            map.current.setFilter('parcel-scoresgeojsonl', ['==', ['get', 'Label'], filterName]);
        }
    }

    useEffect(() => {
        if (props.authRegion) { }
        if (!map.current.isStyleLoaded()) {
            map.current.on('load', () => { applyFilter(props.authRegion) })
        } else {
            applyFilter(props.authRegion)
        }
    }, [props.authRegion])


    return (
        <div ref={mapContainer} className="map-container" />
    );
}

export default Map;
