export const publicLayers = [
    {
        'name': 'Gridded On-Parcel Hazard Index',
        'layer': 'parcel_normalized_sum_risk',
        'help': 'Median value of the on-parcel hazard score for parcels in the grid cell. This score accounts for heat production, ember transport, structure ignition potential, and fuel continuity and is computed from defensible space inspection (DSI) issue reports. Other hazard sources (wildland fuels, fuels on public right of ways, hazards not reported through DSI) are not included. Parcel scores range from 0 to 100, where higher values indicate a greater amount of on-parcel hazard, lower values indicate less hazard. The MWPA-wide median value is 40.3.',
        breaks: [0, 20, 40, 50, 60, 80, 100],
        colors: ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'
    },
    {
        'name': 'Gridded On-Parcel Hazard Hotspots',
        'layer': 'parcel_normalized_sum_risk_hotspot',
        'help': 'Hotspots are statistically-significant groups of grid cells with above-average on-parcel hazard scores whose neighbors also have above average scores. Statistic significance is determined by comparing the observed pattern of hazard to complete spatial randomness. Hotspots are ideal locations for reinspection and additional community engagement.',
        breaks: ["true", "false"],
        colors: ['firebrick', 'white'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'
    },
    // {
    //     'name': 'Gridded Landscape Fire Hazard Index',
    //     'layer': 'Total_Parc',
    //     'help': 'Median value the Landscape fire hazard score produced by Sonoma Technologies for parcels in the grid cell.',
    //     breaks: [0, 5, 10, 20, 30, 40],
    //     colors: ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    // {
    //     'name': 'Gridded Composite Hazard Index',
    //     'layer': 'parcel_normalized_composite',
    //     'help': 'Median value of the composite hazard for parcels in the grid cell. Composite hazard is a measure the combined hazard contributed by both off-parcel factors (fire probability and severity) and on-parcel factors (home hardening and defensible space). Values range from 1 to 73 with a median of 23.9.',
    //     breaks: [0, 10, 20, 30, 40, 50],
    //     colors: ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    // {
    //     'name': 'Gridded Composite Hazard Hotspots',
    //     'layer': 'parcel_normalized_composite_hotspot',
    //     'help': 'Statistically significant spatial hotspots over the median value in the composite hazard score for parcels in the grid cell. Composite hazard is calculated as the average between on-parcel and landscape hazard scores. Hotspots indicate contiguous clusters of high-scoring grid cells.',
    //     breaks: ["true", "false"],
    //     colors: ['firebrick', 'white'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    {
        'name': 'Gridded Defensible Space Index',
        'layer': 'vegetation_normalized_total',
        'help': 'The defensible space index quantifies the combined hazard from vegetation and combustible discoveries. Values range from 0 to 100 with an MWPA-wide median value of 17.9.',
        breaks: [0, 10, 20, 40, 60, 80],
        colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'
    },
    {
        'name': 'Gridded Home Hardening Index',
        'layer': 'total_risk_structure_hazardous',
        'help': 'The home hardening index quantifies the combined hazard from home hardening discoveries, including roof construction, vent screens, eaves, windows, siding material, and other construction and design elements. Values range from 0 to 100 with an MWPA-wide median value of 21.6.',
        breaks: [0, 10, 20, 30, 40, 50],
        normalize_and_max: 4,
        colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'
    },
    {
        'name': 'Gridded Hazard Contribution Ratio',
        'layer': 'veg_structure_ratio',
        'help': 'This ration shows the ratio of defensible space hazards to home hardening hazards for each parcel. A value of 1 indicates equal contributions from defensible space and home hardening discoveries. Values less than 1 indicate a greater proportion of the parcel\'s hazards come from structure materials and design. Values greater than 1 indicate that a greater proportion of the parcel\'s hazard come from vegetation and other defensible space issues.',
        breaks: [0, 0.5, 1, 2, 10],
        colors: ['#842405', '#CCB84F', '#CBEBC7', '#4EA5CA', '#25449F'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'
    },
    // {
    //     'name': 'Gridded Ember Component Index',
    //     'layer': 'parcel_ember_index',
    //     'help': 'Median value of the ember transport index for each parcel in the grid cell.',
    //     breaks: [0, 10, 20, 30, 40, 50],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    // {
    //     'name': 'Gridded Intensity Component Index',
    //     'layer': 'parcel_intensity_index',
    //     'help': 'Median value of the intensity index for parcels in the grid cell.',
    //     breaks: [0, 20, 40, 60, 80, 100],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    // {
    //     'name': 'Gridded Vulnerability Component Index',
    //     'layer': 'parcel_vulnerability_index',
    //     'help': 'Median value of the vulnerability index for parcels in the grid cell. Negative values indicate hazards offset by by resilient findings such as Class A roofs and vent screens <1/8".',
    //     breaks: [-20, 0, 10, 20, 30, 50],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    // {
    //     'name': 'Gridded Continuity Component Index',
    //     'layer': 'parcel_continuity_index',
    //     'help': 'Median value of the continuity index for each parcel in the grid cell.',
    //     breaks: [0, 50, 100, 150, 200, 250],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    // {
    //     'name': 'Gridded Ember Flight Distance',
    //     'layer': 'flight_distance',
    //     'help': 'Mean of Discovery maximum flight distance in meters for simulated embers launched from the grid cell.',
    //     breaks: [0, 250, 500, 750, 1000, 1250],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    // {
    //     'name': 'Gridded Ember Intersection Fraction',
    //     'layer': 'intersection_fraction',
    //     'help': 'Mean fraction of simulated embers that are deposited within 10 feet of a downwind structure for embers originating at discoveries in the grid cell. Values range from 0 to 100%. High values indicate that the discoveries are likely to impact downwind structures with wind-transported embers, potentially crossing parcel- or grid-cell boundaries.',
    //     breaks: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'grid-statsgeojsonl',
    //     visibility: 'public'
    // },
    {
        'name': 'Gridded 2020 Flame Length',
        'layer': 'flame_length_2020',
        'help': 'Mean flame length (in feet) of vegetation and combustible discoveries in the grid cell, computed using the 2020 wind scenario. The 2020 winds include cool, moist, high-velocity winds out of the west.',
        breaks: [0, 4, 8, 11],
        colors: ['green', 'yellow', 'orange', 'firebrick'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'
    },
    {
        'name': 'Gridded 2017 Flame Length',
        'layer': 'flame_length_2017',
        'help': 'Mean flame length (in feet) of vegetation and combustible discoveries in the grid cell, computed using the 2017 wind scenario. The 2017 winds include hot, dry, gusty winds out of the north/northeast.',
        breaks: [0, 4, 8, 11],
        colors: ['green', 'yellow', 'orange', 'firebrick'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'
    },
    {
        'name': 'Gridded Mean Structure Separation Distance',
        'layer': 'structure_sep_distance_mean',
        'help': 'Average distance (in meters) between structures in the grid cell. Tightly spaced structures may be more vulnerable to structure-to-structure ignitions that occur as other structures act as fuel sources once ignited.',
        breaks: [0, 5, 10, 20, 50, 100],
        colors:
            ['#ffffcc', '#c7e9b4', '#7fcdbb', '#41b6c4', '#2c7fb8', '#253494'],
        group: 'grid-statsgeojsonl',
        visibility: 'public'

    }
]

export const privateLayers = [
    {
        'name': 'On-Parcel Hazard Index',
        'layer': 'parcel_normalized_sum_risk',
        'help': ' This score accounts for heat production, ember transport, structure ignition potential, and fuel continuity and is computed from defensible space inspection (DSI) issue reports. Other hazard sources (wildland fuels, fuels on public right of ways, hazards not reported through DSI) are not included. Parcel scores range from 0 to 100, where higher values indicate a greater amount of on-parcel hazard, lower values indicate less hazard. The MWPA-wide median value is 40.3.',
        breaks: [0, 20, 40, 50, 60, 80],
        colors: ['#2b83ba', '#abdda4', '#ffffbf', '#fdae61', '#d7191c'],
        group: 'parcel-scoresgeojsonl',
        visibility: 'private'
    },
    // {
    //     'name': 'Percentile Ran of On-Parcel Hazard Index',
    //     'layer': 'on_parcel_rank',
    //     'help': 'Percentile rank of each parcel\'s on-parcel hazard score relative to all other inspected parcels in the MWPA.',
    //     breaks: [0, 0.20, 0.40, 0.50, 0.60, 0.80, 1.00],
    //     colors: ['#2b83ba','#abdda4','#ffffbf','#fdae61','#d7191c'],
    //     group: 'parcel-scoresgeojsonl',
    //     visibility: 'private'
    // },
    // {
    //     'name': 'Landscape Fire Hazard Index',
    //     'layer': 'Total_Parc',
    //     'help': 'Landscape-scale fire hazard score produced by Sonoma Technologies for each parcel. Scores range from 0 (low) to 100 (high) with an MWPA-wide median value of 6.7.',
    //     breaks: [0, 5, 10, 15, 20, 25],
    //     colors: ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'],
    //     group: 'parcel-scoresgeojsonl',
    //     visibility: 'private'
    // },
    {
        'name': 'Parcel Composite Hazard Index',
        'layer': 'parcel_normalized_composite',
        'help': 'Composite wildfire hazard for each parcel. Composite hazard is computed as the average of the parcel\'s off-parcel (wildfire probability and severity) and on-parcel (home-hardening and defensible space) hazards. Thismindex shows locations that are both at high probability of exposure to an intense fire and has on-parcel risk factors that could result in structure ignition. Scores range from 0 to 100.',
        breaks: [0, 20, 40, 60, 80, 100],
        colors: ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'],
        group: 'parcel-scoresgeojsonl',
        visibility: 'private'
    },
    // {
    //     'name': 'Percentile Rank of Composite Hazard Index',
    //     'layer': 'composite_rank',
    //     'help': 'Percentile rank of each parcel\'s composite hazard score relative to all other inspected parcels in the MWPA',
    //     breaks: [0, 0.20, 0.40, 0.50, 0.60, 0.80, 1.00],
    //     colors: ['#2b83ba','#abdda4','#ffffbf','#fdae61','#d7191c'],
    //     group: 'parcel-scoresgeojsonl',
    //     visibility: 'private'
    // },
    {
        'name': 'Parcel Defensible Space Index',
        'layer': 'vegetation_normalized_total',
        'help': 'The defensible space index quantifies the combined hazard from defensible space discoveries, including vegetation, combustibles, and attachments. Scores range from 0 to 100. ',
        breaks: [0, 20, 40, 60, 80, 100],
        colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
        group: 'parcel-scoresgeojsonl',
        visibility: 'private'
    },
    {
        'name': 'Parcel Home Hardening Index',
        'layer': 'total_risk_structure_hazardous',
        'help': 'The home hardening index quantifies the combined hazards from home hardening discoveries, including structural materials and design. Scores range from 0 to 100.',
        breaks: [0, 10, 20, 30, 40, 50],
        normalize_and_max: 4,
        colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
        group: 'parcel-scoresgeojsonl',
        visibility: 'private'
    },
    {
        'name': 'Parcel Vegetation to Structure Ratio',
        'layer': 'veg_structure_ratio',
        'help': 'Ratio of defensible space hazards to home hardening Hazards. A value of 1 indicates equal contribution from vegetation and structural discoveries. Values less than 1 indicate more structure hazard. Values greater than 1 indicate more vegetation hazard.',
        breaks: [0, 0.5, 1, 2, 10],
        colors: ['#842405', '#CCB84F', '#CBEBC7', '#4EA5CA', '#25449F'],
        group: 'parcel-scoresgeojsonl',
        visibility: 'private'
    },
    // {
    //     'name': 'Parcel Ember Component Index',
    //     'layer': 'parcel_ember_index',
    //     'help': 'Ember transport component index for each parcel. The ember transport component represents the potential for vegetation discoveries to cast long distance embers onto downwind structures.',
    //     breaks: [0, 10, 20, 30, 40, 50],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'parcel-scoresgeojsonl',
    //     visibility: 'private'
    // },
    // {
    //     'name': 'Parcel Intensity Component Index',
    //     'layer': 'parcel_intensity_index',
    //     'help': 'Intensity component index for each parcel. The intensity component represents the potential for vegetation discoveries to expose structures to radiant heating.',
    //     breaks: [0, 20, 40, 60, 80, 100],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'parcel-scoresgeojsonl',
    //     visibility: 'private'
    // },
    // {
    //     'name': 'Parcel Vulnerability Component Index',
    //     'layer': 'parcel_vulnerability_index',
    //     'help': 'Vulnerability component index for each parcel. The vulnerability component represents the marginal contribution to structure ignition probability provided by a structural discovery, given other surveyed attributes on the structure.',
    //     breaks: [-20, -10, 0, 10, 20, 30],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'parcel-scoresgeojsonl',
    //     visibility: 'private'
    // },
    // {
    //     'name': 'Parcel Continuity Component Index',
    //     'layer': 'parcel_continuity_index',
    //     'help': 'Continuity component index for each parcel. The contrinuity component reflects the degree of fuel continuity on each parcel.',
    //     breaks: [0, 25, 50, 75, 100, 200],
    //     colors: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
    //     group: 'parcel-scoresgeojsonl',
    //     visibility: 'private'
    // }
]

export function layerSpecFromID(id) {
    const [visibility, group, layer] = id.split(".");
    let layers = publicLayers;
    if (visibility == 'private') {
        layers = privateLayers;
    }
    for (let i = 0; i < layer.length; i++) {
        const layerSpec = layers[i];
        if (layerSpec.layer == layer && layerSpec.group == group) {
            return layerSpec
        }
    };
    return null
}