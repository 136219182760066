import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';

import firebase from 'firebase/compat/app';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'


import Map from './Map';
import Controls from './Controls';
import { Button, Typography } from '@mui/material';

function App() {
  const [activeLayer, setActiveLayer] = useState("private.parcel-scoresgeojsonl.parcel_normalized_sum_risk");
  const [authState, setAuthState] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [authRegion, setAuthRegion] = useState(false);
  const [demographicOverlay, setDemographicOverlay] = useState("None.None.None");
  const [filterParcel, setFilterParcel] = useState([0, 100]);

  const changeLayer = (layerName) => {
    setActiveLayer(layerName)
  }
  var uiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        disableSignUp: {
          status: true
        }
      }
    ],
  };
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyCx3g_zMVcflQ7H7EoxN6HTVGAxahMdFVo",
      authDomain: "mwpa-opdr-willow.firebaseapp.com",
      projectId: "mwpa-opdr-willow",
      storageBucket: "mwpa-opdr-willow.appspot.com",
      messagingSenderId: "795442352166",
      appId: "1:795442352166:web:0b5c177ac9c1ca6e6ad6e7"
    };
    let ui = null;
    let app;
    if (!firebase.apps.length) {
      app = firebase.initializeApp(firebaseConfig)
      ui = new firebaseui.auth.AuthUI(firebase.auth())
    } else {
      app = firebase.app() // if already initialized, use that one
      ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())
    }
    ui.start('#firebaseui-auth-container', uiConfig);
    firebase.auth().onAuthStateChanged(function (user) {
      console.log(user)
      if (user) {
        if (user.email) {
          setAuthState(user.email);
          setShowAuth(false);

          const db = getFirestore(app);
          const docRef = doc(db, "users", user.email);
          getDoc(docRef).then((d) => {
            const data = d.data();
            const region = data.authregion;
            if (!region) {

            } else {
              setAuthRegion(region);
            }
          }).catch((err) => {
            console.log(err);
          })
        }
      }
    }, function (error) {
      console.log(error);
    });
  }, [showAuth]);

  const executeLogOut = () => {
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
      setAuthState(null);
    }, function (error) {
      // An error happened.
    });
  };

  const changeDemographicOverlay = function (e) {
    console.log(e);
  }

  useEffect(() => {
    if (!authState) {
      setActiveLayer(`public.grid-statsgeojsonl.parcel_normalized_sum_risk`)
    }
  }, [authState]);


  return (
    <div className="App">
      <div style={{ "zIndex": 3, 'position': 'absolute', top: 0, left: 0, backgroundColor: 'white', padding: '12px', borderRadius: 12 }}>
        <Controls activeLayer={activeLayer}
           onLayerChange={changeLayer}
          authState={authState} 
          filter={filterParcel}
          onFilterChange={(e, v)=>{setFilterParcel(v)}}
          activeDemographicLayer={demographicOverlay}
          onDemographicOverlayChange={setDemographicOverlay}
        ></Controls>
      </div>
      <div style={{ "zIndex": 3, 'position': 'absolute', top: 0, right: 0, backgroundColor: 'white', padding: '12px', borderRadius: 12 }}>
        {
          (!authState) ? (
            <div id='authButtons'>
              <Button onClick={() => {
                setShowAuth(!showAuth)
              }}>Log In as Agency User</Button>
              <div id='firebaseui-auth-container' style={{ 'display': (showAuth) ? 'block' : 'none' }}></div>
            </div>
          ) : (
            <div>
              <Typography>{authState}</Typography>
              <Button onClick={executeLogOut}>Log Out</Button>
            </div>
          )
        }


      </div>

      <Map activeLayer={activeLayer} authRegion={authRegion} demographicLayer={demographicOverlay} parcelFilter={filterParcel}></Map>
    </div>
  );
}

export default App;
