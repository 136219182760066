import * as React from 'react';
import {
    Grid, Typography, Button,
    Select, ListSubheader, FormControl, InputLabel, MenuItem, List, Slider,
    Accordion, AccordionDetails, AccordionSummary, Menu
} from '@mui/material';
import { layerSpecFromID, privateLayers, publicLayers } from './layers'
import { useMemo, useState } from 'react';
// import ExpandMoreIcon 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Controls(props) {

    const theLegend = useMemo(() => {
        const elements = [];
        const spec = layerSpecFromID(props.activeLayer);
        for (let i = 0; i < spec.colors.length; i++) {
            const color = spec.colors[i];
            const breakpoint = spec.breaks[i];
            const element = <div style={{ display: 'inline-block', textAlign: 'start', paddingTop: 6 }}>
                <div className='legend-color' style={{ backgroundColor: color, paddingRight: 3, marginRight: 2 }}></div><span style={{ 'marginRight': 16 }}>{breakpoint}</span>
            </div>
            elements.push(element);
        }
        return elements;
    }, [props.activeLayer]);

    const overlayLegend = useMemo(() => {
        const elements = [];
        const [source, table, denom] = props.activeDemographicLayer.split(".");
        console.log(table)
        const minval = 0;
        let maxval = 1;
        if (source === 'None') {
            return []
        }
        if (['B19013001'].includes(table)) {
            maxval = 250000
        } else if (['B25077001'].includes(table)) {
            maxval = 2500000
        } else if (['B02001003', 'B25077001', 'B02001004',
            'B02001005', 'B02001002', 'B28010007'].includes(table)) {
            maxval = 0.05
        }

        else if (['B03003003'].includes(table)) {
            maxval = 0.25
        }
        else if (['C16001005', 'C16001020', 'C16001023',
            'C16001028', 'C16001035', 'C16001011',
            'C16001014', 'C16001017'
        ].includes(table)) {
            maxval = 0.01
        } else if (['B01002001'].includes(table)) {
            maxval = 100
        } else if (['B25003003'].includes(table)) {
            maxval = 0.5
        };
        const colors = ['#edf8e9', '#bae4b3', '#74c476', '#31a354', '#006d2c']

        for (let i = 0; i < colors.length; i++) {
            const color = colors[i];
            console.log(color)
            const breakpoint = ((maxval - minval) / colors.length) * i;
            let formattedBreakpoint = breakpoint;
            if (maxval < 1) {
                formattedBreakpoint = `${(breakpoint * 100).toFixed(1)}%`;
            }
            else if (maxval > 1000) {
                formattedBreakpoint = `${(breakpoint).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
            } else {
                formattedBreakpoint = breakpoint.toFixed(0);
            }
            const element = <div style={{ display: 'inline-block', textAlign: 'start', paddingTop: 6 }}>
                <div className='legend-color' style={{ backgroundColor: color, paddingRight: 3, marginRight: 2 }}></div><span style={{ 'marginRight': 16, fontSize: 'small' }}>{formattedBreakpoint}</span>
            </div>
            elements.push(element);
        }
        console.log(elements)
        return elements;
    }, [props.activeDemographicLayer])

    return (
        <Grid maxWidth={360} container className={'controls'}>
            <Grid item xs={12} alignItems='start'>
                <Typography variant='h6'>On-Parcel Discovery Risk Map</Typography>
                {/* <Typography variant='caption'>Updated 2/14/2023</Typography> */}
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Layer</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.activeLayer}
                        label="Layer"
                        onChange={(e) => { props.onLayerChange(e.target.value) }}
                    >
                        <ListSubheader>Grid-Aggregated Data</ListSubheader>
                        {publicLayers.map((d) => {
                            return <MenuItem key={`${d.visibility}.${d.group}.${d.layer}`} value={`${d.visibility}.${d.group}.${d.layer}`}>{d.name}</MenuItem>
                        })}
                        {(props.authState) ? (
                            <ListSubheader>Parcel-Level Data</ListSubheader>
                        ) : null}
                        {(props.authState) ? (
                            privateLayers.map((d) => {
                                return <MenuItem key={`${d.visibility}.${d.group}.${d.layer}`} value={`${d.visibility}.${d.group}.${d.layer}`}>{d.name}</MenuItem>
                            })
                        ) : null}

                    </Select>
                </FormControl>
            </Grid>
            <Grid sx={{ mt: 4 }}>
                <Typography variant='subtitle2'>
                    {layerSpecFromID(props.activeLayer).name}
                </Typography>
                <Typography variant='caption'>
                    {layerSpecFromID(props.activeLayer).help}
                </Typography>
                <br />
                <hr />
                {theLegend}
            </Grid>
            <Grid sx={{ mt: 4 }} item xs={12}>
                <Accordion alignItems={'start'} textAlign={'start'}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Advanced Controls</Typography>
                    </AccordionSummary>
                    <AccordionDetails alignItems={'start'} textAlign={'start'} width={360}>
                        <Typography>
                            Filter by Parcel Hazard Score
                        </Typography>
                        <Slider
                            aria-label="Temperature"
                            valueLabelDisplay="auto"
                            value={props.filter}
                            onChange={props.onFilterChange}
                            step={10}
                            marks
                            min={0}
                            max={100}
                        ></Slider>


                        <InputLabel id="demographic-select">Demographic Overlay</InputLabel>
                        <Typography sx={{fontSize: 10}}>Demographic data from the <a target='_blank' href='https://www.census.gov/programs-surveys/acs'>2021 American Community Survey</a></Typography>
                        <Select
                            labelId="demographic-select"
                            id="demographic-select"
                            value={props.activeDemographicLayer}
                            label="Overlay"
                            sx={{ width: '100%' }}
                            onChange={(e) => { props.onDemographicOverlayChange(e.target.value) }}
                        >
                            <MenuItem value="None.None.None">No Overlay</MenuItem>
                            <MenuItem value="median_income.B19013001.None">Median Household Income</MenuItem>
                            <MenuItem value="home_value.B25077001.None">Median Property Value</MenuItem>
                            <MenuItem value="race.B02001003.B02001001">Race, Percent Black or African American</MenuItem>
                            <MenuItem value="race.B02001004.B02001001">Race, Percent American Indian</MenuItem>
                            <MenuItem value="race.B02001005.B02001001">Race, Percent Asian</MenuItem>
                            <MenuItem value="hispanic.B03003003.B03003001">Percent Hispanic or Latino Origin</MenuItem>
                            <MenuItem value="language.C16001002.C16001001">Language, Percent English-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001005.C16001001">Language, Percent Spanish-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001020.C16001001">Language, Percent Korean-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001023.C16001001">Language, Percent Chinese-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001028.C16001001">Language, Percent Filipino-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001035.C16001001">Language, Percent Arabic-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001011.C16001001">Language, Percent German-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001014.C16001001">Language, Percent Russian-Speakers Alone</MenuItem>
                            <MenuItem value="language.C16001017.C16001001">Language, Percent Indo-European-Speakers Alone</MenuItem>
                            <MenuItem value="computers.B28010007.B28010001">No Computer or Smartphone at Home</MenuItem>
                            <MenuItem value='median_age.B01002001.None'>Median Age</MenuItem>
                            <MenuItem value='tenure.B25003003.B25003001'>Percent Renter Occupied Housing</MenuItem>
                        </Select>
                        <br />
                        {overlayLegend}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )

}